<template>
  <div class="mt-3" v-show="formIsOpen">
    <form @submit.prevent="formSubmit">
      <div class="mx-3 my-3">
        <va-select v-model="data.type" :options="options" />
      </div>
      <va-input
        class="mb-4 mx-3"
        v-model="data.title"
        :placeholder="$t('title')"
      />
      <va-input
        class="mb-4 mx-3"
        v-model="data.title_ar"
        :placeholder="$t('title_ar')"
      />
      <v-quill
        id="content"
        toolbar="full"
        content-type="html"
        v-model:content="data.text"
        required
        :placeholder="$t('content')"
      />
      <v-quill
        id="content_ar"
        toolbar="full"
        content-type="html"
        v-model:content="data.text_ar"
        required
        :placeholder="$t('content_ar')"
      />
      <va-file-upload
        class="my-4 mx-3"
        @change="previewFile($event)"
        v-if="data.type == 'video' || data.type == 'image'"
        v-model="data.file"
        :file-types="data.type=='image'?'.jpg,.png' : '.mp4,.webm,.flv,.mkv'"
        required
      />
      <img
        v-if="!data.file || data.file?.length == 0"
        :src="$store.state.imgUrl + data.path"
        :style="{'display': data.type == 'image' ? 'block' : 'none'}"
        style="width: 250px; height: 250px"
      />
      <img
        id="image"
        v-if="data.file?.length > 0"
        style="display: none; width: 250px; height: 250px"
      />
      <video
      v-if="data.file?.length == 0"
        :style="{'display': data.type == 'video' ? 'block' : 'none'}"
        style="width:350px;aspect-ratio:16/9"
        :src="$store.state.imgUrl + data.path"
        controls
      ></video>
      <video
      id="video"
      v-else
      style="display:none;width:350px;aspect-ratio:16/9"
      controls
    ></video>

      <div class="card_footer">

        <va-button 
        :disabled='loading' 
        :loading="loading" 
        color="success" 
        class="float-end mt-4" 
        type="submit">
        {{$t("save")}}
        </va-button>
      </div>
    </form>
  </div>
</template>

<script>
import { GraphQLClient, gql } from "graphql-request";
import { ref } from "vue";

export default {
  props: ["formIsOpen", "selectedRowToEdit"],
  data() {
    return {
      data: { text: "", text_ar: "" },
      options: ["text", "image", "video"],
      loading: false
    };
  },
  emits: ["closeForm", "rowInserted", "rowUpdated"],
  computed: {
    showInput() {
      return this.data.type == "image" || this.data.type == "video";
    },
  },
  methods: {
    closeForm() {
      this.$emit("closeForm");
    },
    async formSubmit() {
      this.loading = true
      const CHAMPIONSHIP_NEWS_INSERT = gql`
        mutation (
          $champ_id: Int
          $title: String
          $title_ar: String
          $type: String
          $text: String
          $text_ar: String
          $file: Upload
        ) {
          query: insertChampNews(
            insertChampNewsInput: {
              champ_id: $champ_id
              type: $type
              title: $title
              text: $text
              title_ar: $title_ar
              text_ar: $text_ar
              file: $file
            }
          ) {
            status
          }
        }
      `;
      const CHAMPIONSHIP_NEWS_UPDATE = gql`
        mutation (
          $id: Int
          $title: String
          $title_ar: String
          $type: String
          $text: String
          $text_ar: String
          $file: Upload
        ) {
          query: updateChampNews(
            updateChampNewsInput: {
              id: $id
              type: $type
              title: $title
              text: $text
              title_ar: $title_ar
              text_ar: $text_ar
              file: $file
            }
          ) {
            status
          }
        }
      `;
      const graphQLClient = new GraphQLClient(this.$store.state.appUrl, {
        headers: {
          // authorization: 'Bearer MY_TOKEN',
        },
      });

      const variables = {
        title: this.data.title,
        text: this.data.text,
        title_ar: this.data.title_ar,
        text_ar: this.data.text_ar,
        file: this.data.file?.[0],
        type: this.data.type,
        champ_id: Number(this.$route.params.id),
      };

      let query = "";
      let operation = ""
      if (!this.data.id) {
        // Add Section
        operation = 'insert'
        query = CHAMPIONSHIP_NEWS_INSERT;
      } else {
        //edit Section
        operation = 'update'
        query = CHAMPIONSHIP_NEWS_UPDATE;
        delete variables.champ_id;
        variables.id = this.data.id;
      }
      try {
        const data = await graphQLClient.request(query, variables);
        if (data.query.status) {
          this.loading = false
          this.$swal.fire({
            title: operation == "insert" ? this.$t('add_news_success') : this.$t('edit_news_success'),
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          this.loading = false
          this.$swal.fire({
            title: operation == "insert" ? this.$t('add_news_fail') : this.$t('edit_news_fail'),
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.data = {};
        if (this.selectedRowToEdit) {
          this.$emit("rowUpdated", data.AcademyServicesUpdate);
        } else {
          this.$emit("rowInserted", data.AcademyServicesCreate);
        }
        // this.closeForm();
      } catch (err) {
        console.log(err);
        this.loading =false
      }
    },
    previewFile() {
      console.log(this.data.file?.length);
      if (this.data.file?.length > 0) {
        let file = this.data.file[0];
        let blobURL = URL.createObjectURL(file);
        if (this.data.type == "video") {
          document.querySelector("#video").style.display = "block";
          document.querySelector("#video").src = blobURL;
          return;
        }
        if (this.data.type == "image") {
          document.querySelector("#image").style.display = "block";
          document.querySelector("#image").src = blobURL;
          return;
        }
      } else {
        document.querySelector("#video").style.display = "none";
        document.querySelector("#image").style.display = "none";
      }
    },
  },
  watch: {
    selectedRowToEdit(n) {
      // console.log(JSON.stringify(this.selectedRowToEdit));

      if (this.selectedRowToEdit) {
        this.data.id = this.selectedRowToEdit.id;
        this.data.title = this.selectedRowToEdit.title;
        this.data.title_ar = this.selectedRowToEdit.title_ar;
        this.data.path = this.selectedRowToEdit.path;
        this.data.type = this.selectedRowToEdit.type;
        document.querySelector("#content .ql-editor").innerHTML = this
          .selectedRowToEdit.text
          ? ref(this.selectedRowToEdit.text).value
          : "";
        document.querySelector("#content_ar .ql-editor").innerHTML = this
          .selectedRowToEdit.text_ar
          ? ref(this.selectedRowToEdit.text_ar).value
          : "";
      }
    },
  },
};
</script>

<style>
.md6 {
  display: flex;
}
.ck.ck-editor {
  margin: 19px;
}
.float-end {
  display: block;
  margin-left: auto;
  margin-right: 1rem;
}
body.ar .float-end {
  margin-right: auto;
  margin-left: 1rem;
}
</style>
