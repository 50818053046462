<template>
  <va-inner-loading :loading="loading" icon="restart_alt">
    <va-card gradient color="#f0f0f0" stripe stripe-color="#013c84" class="mt-4">
      <va-card-title>{{ $t("general_info") }}</va-card-title>
      <va-card-content class="p-5" style="margin-bottom: 25px">
        <div class="row justify--space-between" style="padding: 25px">
          <va-avatar
            style="width: 6rem; height: 6rem"
            :src="$store.state.imgUrl + championship.logo"
          />
          <h1 class="text-center" style="font-size: 2rem; display: inline">
            {{ championship.name }}
          </h1>

          <va-chip shadow flat class="flex">
            <div>{{ $t("start_time") }}</div>
            <div>{{ championship.suggested_start_time }}</div>
          </va-chip>
        </div>
        <h1 class="text-center" style="font-size: 1.5rem">
          {{ $t("by") }} : {{ championship_creator }}
          <hr />
        </h1>
        <div class="row md6 justify--space-around" style="padding: 25px">
          <p style="line-height: 2.2">
            <strong>{{ $t("teams_count") }} </strong> :
            {{ championship.teams_count }}
            <br />
            <strong>{{ $t("players_count") }} </strong> :
            {{ championship.team_players_count }}
            <br />
            <strong>{{ $t("championship_type") }}</strong> : {{ champ_type }}
            <br />
            <section class="champ_editors" v-if="$store.state.userData?.user_role?.type == 'admin' ">
            <span
              v-if="championship.editors && championship.editors.length > 0"
            >
              <strong>{{ $t("menu.editors") }}</strong> <br />
              <va-chip
                v-for="editor in championship.editors"
                :key="editor"
                size="small"
                class="mr-1 my-1"
                closeable
                @update:modelValue="deleteEditor(editor.id)"
              >
                {{ editor.user.name }}
              </va-chip>
            </span>
            <br />
            <span>
              <va-select
                class="my-4 px-2"
                :options="editors"
                :label="$t('choose_editor')"
                v-model="selectedEditor_id"
                :value-by="(editor) => editor.id"
                :text-by="(editor) => editor.name"
                :track-by="(editor) => editor.id"
                @update:model-value="assignEditor"
                searchable
                clearable
              >
              </va-select>
            </span>
            </section>
          </p>
          <div style="line-height: 2.2">
            <strong>{{ $t("menu.referees") }}</strong>
            <ul>
              <li
                class="pl-2"
                v-for="referee in championship.referees"
                :key="referee"
              >
                {{ referee.user?.name }}
              </li>
            </ul>
          </div>
        </div>
      </va-card-content>
    </va-card>
    <va-card gradient color="#f0f0f0" class="mt-4">
      <va-card-title>{{ $t("championship_playgrounds") }}</va-card-title>
      <va-card-content
        class="p-5 playgrounds-row justify--space-around"
        style="margin-bottom: 25px"
      >
        <va-card
          square
          outlined
          style="width: 100%"
          class="flex lg3 md6 px-2 mx-2 my-2"
          v-for="playground in championship.playgrounds"
          :key="playground.id"
        >
          <va-image
            :src="$store.state.imgUrl + playground.img"
            style="height: 200px"
          >
            <template #loader>
              <va-progress-circle indeterminate />
            </template>
            <template #error> Failed to load the image! :( </template>
          </va-image>
          <va-card-title style="font-size: 1rem; line-break: anywhere">{{
            playground.name
          }}</va-card-title>
          <va-card-content style="line-height: 1.5">
            <caption class="w-max">
              {{
                playground.type
              }}
            </caption>
            <caption class="w-max">
              {{
                playground.location
              }}
            </caption>
          </va-card-content>
        </va-card>
      </va-card-content>
    </va-card>
  </va-inner-loading>
</template>

<script>
import { gql, request } from "graphql-request";
export default {
  name: "championship-info",
  data() {
    return {
      championship: {},
      editors: [],
      selectedEditor_id: null,
      editorsLoading: true,
      loading: true,
    };
  },
  computed: {
    championship_creator() {
      if (this.championship.creator) {
        return this.championship.creator.name;
      }
    },
    champ_type() {
      if (this.championship.champ_type && this.$i18n.locale == "gb") {
        return this.championship.champ_type.name;
      } else if (this.championship.champ_type && this.$i18n.locale == "sa") {
        return this.championship.champ_type.name_ar;
      } else {
        return "";
      }
    },
  },
  async created() {
    try {
      this.getEditors();
      this.getChampInfo();
    } catch (error) {
      console.log(error);
      this.loading = false;
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("errors.network"),
          text: "Try Again in a minute",
          confirmButtonColor: "#023b81",
          confirmButtonText: "Reload",
        })
        .then((result) => {
          if ((result.value)) {
            location.reload();
          }
        });
    }
  },
  methods: {
    async getChampInfo() {
      const GET_CHAMP_INFO = gql`
        query championshipInfo($id: Int) {
          Champs(where: { id: $id }) {
            id
            name
            logo
            teams_count
            team_players_count
            suggested_start_time
            champ_type {
              name
              name_ar
            }
            creator {
              name
            }
            playgrounds: champ_playgrounds {
              img
              name
              location
              type
            }
            referees: champ_referees {
              user {
                name
              }
            }
            editors: champ_editors {
              id
              user {
                id
                name
              }
            }
          }
        }
      `;
      const response = await request(this.$store.state.appUrl, GET_CHAMP_INFO, {
        id: Number(this.$route.params.id),
      });
      if (response.Champs.length > 0) {
        this.championship = response.Champs[0];
        this.loading = false;
        /* Filter  Editors array and elimiate the editors that are already assigned to the current Championship*/
        this.championship.editors.forEach((champEditor) => {
          this.editors = this.editors.filter(
            (editor) => editor.id != champEditor.user.id
          );
        });
      } else {
        this.$router.replace({ name: "not-found-large-text" });
      }
    },
    async getEditors() {
      const GET_EDITORS = gql`
        query {
          editors: Users(where: { user_role_id: 4 }) {
            id
            name
          }
        }
      `;
      const getEditorData = await request(
        this.$store.state.appUrl,
        GET_EDITORS
      );
      this.editors = getEditorData.editors;
      this.editorsLoading = false;
    },
    async assignEditor() {
      const ASSIGN_EDITOR = gql`
        mutation ($champ_id: Int, $user_id: Int) {
          assign_editor:ChampEditorsCreate(
            champ_editors: { champ_id: $champ_id, user_id: $user_id }
          ) {
            id
          }
        }
      `;
      try {
          const response = await request(this.$store.state.appUrl,ASSIGN_EDITOR,
          {
            champ_id: this.championship.id,
            user_id: this.selectedEditor_id,
          });
          if(response.assign_editor?.id){
            this.$swal.fire({
                icon: "success",
                title: this.$t("add_editor_success"),
                showConfirmButton: false,
                timer: 1500,
            })
            this.selectedEditor_id = null;
            this.getChampInfo();
            this.getEditors();
          }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
                icon: "error",
                title: this.$t("add_editor_fail"),
                showConfirmButton: false,
                timer: 1500,
        })
      }
    },
    async deleteEditor(editor_id) {
      this.$swal.fire({
          title: this.$t("sure?"),
          text: this.$t("delete_text"),
          icon: "question",
          showCancelButton: true,
          
          confirmButtonColor: "#e42222",
          confirmButtonText: this.$t("delete"),
          cancelButtonText: this.$t("cancel"),
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const DELETE_EDITOR = gql`
              mutation ($id: Int!) {
                deleted: ChampEditorsDelete(id: $id)
              }
            `;
            const response = await request(
              this.$store.state.appUrl,
              DELETE_EDITOR,
              { id: editor_id }
            );
            if (response.deleted) {
              this.$swal.fire({
                icon: "success",
                title: this.$t("delete_editor_success"),
                showConfirmButton: false,
                timer: 1500,
              });
              this.getChampInfo();
              this.getEditors();
            }
            else{
              this.$swal.fire({
                icon: "error",
                title: this.$t("delete_editor_fail"),
                showConfirmButton: false,
                timer: 1500,
              });
            }
          }
        });
    },
  },
};
</script>

<style>
.w-max {
  width: max-content;
}
.playgrounds-row {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 555px) {
  .playgrounds-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
}
.va-chip__content {
  flex-direction: column !important;
}
</style>
