<template>
  <va-inner-loading :loading="loading" icon="restart_alt">
    <va-card class="mt-4" gradient stripe stripe-color="#595cff">
      <div class="row px-3">
        <!-- First Column -->
        <div class="flex flex-grow sm12 md4 lg2 my-2">
          <va-card
            style="min-height: 50%"
            class="my-2"
            gradient
            color="#388ac1"
          >
            <va-card-title class="row align--end">
              <img
                class="px-2"
                src="/img/football.png"
                style="width: 40px"
                alt="ball"
              />
            </va-card-title>
            <va-card-content class="text-light">
              <div class="col justify--space-evenly align--center pb-3">
                <strong class="text-larger">{{ stats.total_goals }}</strong>
                <strong>{{ $t("total_goals") }}</strong>
              </div>
              <div class="col justify--space-evenly align--center pt-3">
                <strong class="text-larger">
                  {{ stats.total_goals / stats.total_matches || 0 }}
                </strong>
                <strong>{{ $t("goals_rate") }}</strong>
              </div>
            </va-card-content>
          </va-card>
          <!-- Red\Yellow Cards stats -->
          <va-card style="min-height: 50%" gradient color="#388ac1">
            <va-card-title class="text-light">{{ $t("cards") }}</va-card-title>
            <va-card-content class="text-light">
              <div class="row justify--space-evenly align--center py-3">
                <strong class="text-large">{{ stats.total_cards }}</strong>
                <img src="/img/cards.png" style="width: 32px" alt="" />
              </div>
              <div class="row justify--space-evenly align--center py-3">
                <strong class="text-large">{{
                  stats.total_yellow_cards
                }}</strong>
                <img src="/img/yellow.png" style="width: 32px" alt="" />
              </div>
              <div class="row justify--space-evenly align--center py-3">
                <strong class="text-large">{{ stats.total_red_cards }}</strong>
                <img src="/img/red.png" style="width: 32px" alt="" />
              </div>
            </va-card-content>
          </va-card>
        </div>
        <!-- Second Column -->
        <div class="flex flex-grow sm12 md4 lg5 my-2">
          <!-- Top Teams by Cards -->
          <va-card
            :style="
              stats.teams_total_cards?.length > 0 ? '' : 'min-height:50%;'
            "
            class="mt-2 mb-4"
            gradient
            color="#1e6091"
          >
            <va-card-title class="row justify--space-between">
              <span class="px-2 text-light">{{ $t("aggressive") }} </span>
              <img
                class="px-2"
                src="/img/cards.png"
                style="width: 40px"
                alt=""
              />
            </va-card-title>
            <va-card-content>
              <div
                class="row justify--space-around"
                v-if="stats.teams_total_cards?.length > 0"
              >
                <va-card
                  gradient
                  color="#dbd8d3"
                  v-for="team in stats.teams_total_cards"
                  :key="team"
                  style="
                    line-height: 2;
                    position: relative;
                    max-width: max-content;
                  "
                  class="py-4 px-4 my-2 mx-2"
                >
                  <va-card-content class="team-container justify--center">
                    <va-avatar :src="$store.state.imgUrl + team.logo" />
                    <strong class="px-2 py-2"> {{ team.name }}</strong>
                    <span class="badge">{{ team.total_cards }}</span>
                  </va-card-content>
                </va-card>
              </div>
              <div v-else>
                <h1 class="text-light">{{ $t("no_stats") }}</h1>
              </div>
            </va-card-content>
          </va-card>
          <!-- Top Assistants -->
          <va-card
            :style="stats.top_assistants?.length > 0 ? '' : 'min-height:50%;'"
            gradient
            color="#1e6091"
            class="mt-2"
          >
            <va-card-title class="row justify--space-between text-light">
              <span class="px-2 py-2">{{ $t("top_assist") }}</span>
              <img
                class="px-2 py-2"
                src="/img/assist.png"
                style="width: 55px"
                alt="assist goal"
              />
            </va-card-title>
            <va-card-content>
              <div v-if="stats.top_assistants?.length > 0">
                <div
                  v-for="(player, index) in stats.top_assistants"
                  :key="player"
                  style="line-height: 2"
                  class="row justify--space-between px-2 py-3 text-light"
                >
                  <strong> {{ index + 1 + "- " + player.name }} </strong>
                  <span> {{ player.assists }} </span>
                </div>
              </div>
              <div v-else>
                <h1 class="text-light">{{ $t("no_stats") }}</h1>
              </div>
            </va-card-content>
          </va-card>
        </div>
        <!-- Third Column -->
        <div class="flex sm12 md4 lg5 my-2">
          <!-- Top Scorer -->
          <va-card
            :style="stats.top_scorers?.length > 0 ? '' : 'min-height:50%;'"
            gradient
            color="#00357a"
            class="mt-2 mb-4"
          >
            <va-card-title class="row justify--space-between text-light">
              <span class="px-2 py-2">{{ $t("top_scorer") }}</span>
              <img
                class="px-2 py-2"
                src="/img/football.png"
                style="width: 40px"
                alt="ball"
              />
            </va-card-title>
            <va-card-content class="text-light">
              <div v-if="stats.top_scorers?.length > 0">
                <div
                  v-for="(player, index) in stats.top_scorers"
                  :key="player"
                  style="line-height: 2"
                  class="row justify--space-between px-2 py-3"
                >
                  <strong> {{ index + 1 + "- " + player.name }} </strong>
                  <strong> {{ player.goals }} </strong>
                </div>
              </div>
              <div v-else>
                <h1 class="text-light">{{ $t("no_stats") }}</h1>
              </div>
            </va-card-content>
          </va-card>
          <!-- Top Teams by Goals -->
          <va-card
            :style="stats.teams_goals?.length > 0 ? '' : 'min-height:50%;'"
            class="mt-2"
            gradient
            color="#00357a"
          >
            <va-card-title class="row justify--space-between">
              <span class="px-2 text-light">{{ $t("top_scorer") }} </span>
              <img
                class="px-2"
                src="/img/football.png"
                style="width: 40px"
                alt=""
              />
            </va-card-title>
            <va-card-content>
              <div
                class="row justify--space-around"
                v-if="stats.teams_goals?.length > 0"
              >
                <va-card
                  gradient
                  color="#dbd8d3"
                  v-for="team in stats.teams_goals"
                  :key="team"
                  style="line-height: 2; position: relative"
                  class="py-4 px-4 my-2 mx-2 lg2 sm12"
                >
                  <va-card-content class="team-container justify--center">
                    <va-avatar :src="$store.state.imgUrl + team.logo" />
                    <strong class="px-2 py-2"> {{ team.name }}</strong>
                    <span class="badge">{{ team.goals }}</span>
                  </va-card-content>
                </va-card>
              </div>
              <div v-else>
                <h1 class="text-light">{{ $t("no_stats") }}</h1>
              </div>
            </va-card-content>
          </va-card>
        </div>
      </div>
    </va-card>
  </va-inner-loading>
</template>

<script>
import { request } from "graphql-request";
import { CHAMP_STATS } from "@/graphql/queries/stats";

export default {
  name: "champ-stats",
  data() {
    return {
      stats: {},
      loading: true,
    };
  },
  mounted() {
    try {
      this.getChampStats();
    } catch (error) {
      this.loading = false;
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("errors.network"),
          text: "Try Again in a minute",
          confirmButtonColor: "#023b81",
          confirmButtonText: "Reload",
        })
        .then((result) => {
          if (result.value) {
            location.reload();
          }
        });
    }
  },
  methods: {
    async getChampStats() {
      const response = await request(this.$store.state.appUrl, CHAMP_STATS, {
        id: +this.$route.params.id,
      });
      this.stats = response.champStats;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.badge {
  display: inline-flex;
  position: absolute;
  color: #00357a;
  font-size: 1.2rem;
  font-weight: 700;
  top: 7px;
  left: 7px;
}
.team-container {
  display: flex;
  flex-direction: column;
  padding: 0.8rem 1.2rem;
  border-radius: 5px;
  align-items: center;
}
</style>
