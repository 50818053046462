<template>
  <va-inner-loading :loading="loading" icon="restart_alt">
    <div class="mt-5">
      <div v-if="teams.length > 0">
        <!-- Groups -->
        <div
          v-if="champ_type == 3"
          style="flex-wrap: wrap"
          class="teams-row justify--space-evenly"
        >
          <va-card
            class="md12"
            style="min-width: 22%; margin: 1rem 0"
            v-for="team in teams"
            :key="team.id"
          >
            <va-image
              style="height: 200px; top: 5px"
              :src="$store.state.imgUrl + team.team.logo"
            >
              <template #loader>
                <va-progress-circle indeterminate />
              </template>
              <template #error> Failed to load the image! :( </template>
            </va-image>
            <va-chip square>
              {{ team.champ_group }}
            </va-chip>
            <va-card-title>{{ team.team.name }}</va-card-title>
            <va-card-content style="line-height: 1.9">
              {{ `${$t("coach")} : ${team.team.coach}` }} <br />
              {{ `${$t("manager")} : ${team.team.manager}` }}
            </va-card-content>
          </va-card>
        </div>
        <!-- Other Types ( knockout - league ) -->
        <div
          v-else
          style="flex-wrap: wrap"
          class="teams-row justify--space-evenly"
        >
          <va-card
            style="min-width: 30%; margin: 1rem 0"
            v-for="team in teams"
            :key="team.id"
            stripe
            :stripe-color="team.color"
          >
            <va-image
              style="height: 200px; top: 5px"
              :src="$store.state.imgUrl + team.team.logo"
            >
              <template #loader>
                <va-progress-circle indeterminate />
              </template>
              <template #error> Failed to load the image! :( </template>
            </va-image>
            <va-card-title>{{ team.team.name }}</va-card-title>
            <va-card-content style="line-height: 1.9"
              >{{ team.team.coach }} <br />
              {{ team.team.manager }}</va-card-content
            >
          </va-card>
        </div>
      </div>
      <va-card stripe stripe-color="#013c84" class="mt-4" v-else>
        <va-card-content>
          <div
            class="col align--center justify--space-evenly"
            style="height: 35vh"
          >
            <h1 style="font-size: 32px">
              {{ $t("no_teams") }}
              <span class="material-icons"> flag </span>
            </h1>
          </div>
        </va-card-content>
      </va-card>
    </div>
  </va-inner-loading>
</template>

<script>
import { gql, GraphQLClient, request } from "graphql-request";
export default {
  name: "championship-teams",
  data() {
    return {
      teams: [],
      champ_type: "",
      loading: true,
    };
  },
  async created() {
    try {
      const client = new GraphQLClient(this.$store.state.appUrl, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
      this.champ_type = JSON.parse(localStorage["champ_type"]);
      const GET_CHAMP_TEAMS = gql`
        query ($id: Int) {
          Champs(id: $id) {
            teams: champ_teams(order: "champ_group") {
              champ_group
              team {
                name
                logo
                manager_id
                coach_id
              }
            }
          }
        }
      `;
      const response = await client.request(GET_CHAMP_TEAMS, {
        id: Number(this.$route.params.id),
      });
      this.teams = response.Champs[0].teams;
      for (let i = 0; i < this.teams.length; i++) {
        const GET_USER_NAME = gql`
          query ($id: Int) {
            Users(id: $id) {
              name
            }
          }
        `;
        const manager = await request(this.$store.state.appUrl, GET_USER_NAME, {
          id: this.teams[i].team.manager_id,
        });
        this.teams[i].team.manager = manager.Users[0].name;
        if (this.teams[i].team.coach_id) {
          const coach = await request(this.$store.state.appUrl, GET_USER_NAME, {
            id: this.teams[i].team.coach_id,
          });
          this.teams[i].team.coach = coach.Users[0].name;
        }
      }
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.loading = false;
      // this.$swal.fire({
      //     icon: 'warning',
      //     title: this.$t('errors.network'),
      //     text: 'Try Again in a minute',
      //     confirmButtonColor:"#023b81",
      //     confirmButtonText:"Reload"
      // }).then((result)=>{
      //     if(result.value=true){
      //     location.reload()
      //     }
      // })
    }
  },
};
</script>

<style scoped>
.teams-row {
  display: flex;
  flex-wrap: wrap !important;
}
@media (max-width: 555px) {
  .teams-row {
    display: flex;
    flex-direction: column;
  }
}
.va-image__error,
.va-image__img,
.va-image__loader,
.va-image__overlay {
  width: 100%;
  position: absolute;
  top: 0 !important;
  bottom: 0 !important;
  left: unset !important;
  right: unset !important;
}
.va-image__img img {
  object-fit: contain !important;
}
</style>
