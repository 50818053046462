<template>
  <va-inner-loading :loading="loading" icon="restart_alt">
    <div v-if="levels.length > 0">
      <va-card
        v-for="(level, index) in levels"
        :key="level.id"
        gradient color="#f0f0f0"
        stripe
        stripe-color="#013c84"
        class="mt-4"
      >
        <va-card-title> Round {{ index + 1 }} </va-card-title>
        <va-card-content class="p-5 lg6" style="margin-bottom: 25px">
          <va-list>
            <va-list-label> Matches </va-list-label>

            <va-list-item
              class="flex row justify-content--around"
              v-for="match in level.matches"
              :key="match"
            >
              <div v-if="match.team1" class="flex-col" style="width: 25%">
                <va-list-item-section avatar>
                  <va-avatar
                    style="overflow: hidden !important"
                    size="large"
                    font-size="24px"
                  >
                    <img
                      style="width: 100px"
                      v-if="match.team1.logo"
                      :src="$store.state.imgUrl + match.team1.logo"
                    />
                    <div v-else>{{ match.team1.name.substring(0, 1) }}</div>
                  </va-avatar>
                </va-list-item-section>

                <va-list-item-section class="mt-3">
                  <h4>
                    {{ match.team1.name }}
                  </h4>
                </va-list-item-section>
              </div>

              <!-- Match Status (  To Be Decide | Upcoming | Live | Result  )  -->
              <div v-if="!match.start_time">
                <va-badge text="TBD" color="warning" />
              </div>
              <div
                v-else-if="
                  new Date(match.start_time) < Date.now() &&
                  Date.now() < new Date(match.finish_time)
                "
              >
                <va-badge
                  text="Live"
                  color="success"
                  :transparent="live"
                  class="blinking"
                />
              </div>
              <div
                class="flex-col"
                style="line-height: 1.7"
                v-else-if="
                  match.start_time && new Date(match.start_time) > Date.now()
                "
              >
                <h3>VS</h3>
                <h3 style="text-align: center" v-if="match.start_time">
                  {{ new Date(match.start_time).toLocaleDateString() }}
                  <br />
                  {{ new Date(match.start_time).toLocaleTimeString() }}
                </h3>
              </div>

              <div v-else>
                <va-badge text="Result" color="primary" />
              </div>
              <!-- /Match Status -->

              <div v-if="match.team2" class="flex-col" style="width: 25%">
                <va-list-item-section avatar>
                  <va-avatar
                    style="overflow: hidden !important"
                    size="large"
                    font-size="24px"
                  >
                    <img
                      style="width: 100px"
                      v-if="match.team1.logo"
                      :src="$store.state.imgUrl + match.team2.logo"
                    />
                    <div v-else>{{ match.team2.name.substring(0, 1) }}</div>
                  </va-avatar>
                </va-list-item-section>

                <va-list-item-section class="mt-3">
                  <h4>
                    {{ match.team2.name }}
                  </h4>
                </va-list-item-section>
              </div>
            </va-list-item>
          </va-list>
        </va-card-content>
      </va-card>
    </div>
    <va-card gradient color="#f0f0f0" stripe stripe-color="#013c84" class="mt-4" v-else>
      <va-card-content>
        <div
          class="col align--center justify--space-evenly"
          style="height: 35vh"
        >
          <h1 style="font-size: 32px">
            {{ $t("no_matches") }}
            <span class="material-icons"> sports_soccer </span>
          </h1>
        </div>
      </va-card-content>
    </va-card>
  </va-inner-loading>
</template>

<script>
import { gql, request } from "graphql-request";

export default {
  name: "championship-schedule",
  data() {
    return {
      levels: [],
      loading: true,
    };
  },
  async mounted() {
    try {
      const GET_CHAMP_LEVELS = gql`
        query ($id: Int) {
          ChampLevels(where: { champ_id: $id }) {
            id
            matches(order: "start_time") {
              team1 {
                name
                logo
              }
              team2 {
                name
                logo
              }
              start_time
              finish_time
              playground {
                name
                img
                location
                type
              }
              match_referees {
                user {
                  name
                }
              }
            }
          }
        }
      `;
      const response = await request(
        this.$store.state.appUrl,
        GET_CHAMP_LEVELS,
        { id: Number(this.$route.params.id) }
      );
      this.levels = response.ChampLevels;
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.loading = false;
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("errors.network"),
          text: "Try Again in a minute",
          confirmButtonColor: "#023b81",
          confirmButtonText: "Reload",
        })
        .then((result) => {
          if (result.value) {
            location.reload();
          }
        });
    }
  },
};
</script>

<style>
.va-list-item__inner {
  justify-content: space-evenly !important;
}
.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.va-badge__text {
  font-size: 12px;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.blinking {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
</style>
