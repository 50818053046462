<template>
<!-- Breadcrumb  -->
    <va-card class="row align--center">
        <va-card-content> 
        <va-breadcrumbs color="primary">
            <va-breadcrumbs-item  :label="$t('championships')" />
            <va-breadcrumbs-item :to="{ name:route }"  :label="$t(`menu.${route}`)" />
            <va-breadcrumbs-item  :label="championship_name" />
        </va-breadcrumbs>
        </va-card-content>
    </va-card>
<!-- Content  -->
    <va-tabs grow hide-slider v-model="selectedTab" class="mt-5">
        <template #tabs>
            <va-tab name="info" >
            <span class="material-icons">info</span>
            <h6 style="padding:0 10px">{{ $t('info') }}</h6>
            </va-tab>

            <va-tab name="schedule">
            <span class="material-icons">calendar_month</span>
            <h6 style="padding:0 10px">{{ $t('schedule') }}</h6>
            </va-tab>

            <va-tab name="teams">
            <span class="material-icons">flag</span>
            <h6 style="padding:0 10px">{{ $t('menu.teams') }}</h6>        
            </va-tab>

            <va-tab name="feeds" >
            <span class="material-icons">feed</span>
            <h6 style="padding:0 10px">{{ $t('feeds') }}</h6>
            </va-tab>

            <va-tab name="stats">
            <span class="material-icons">analytics</span>
            <h6 style="padding:0 10px">{{ $t('stats') }}</h6>
            </va-tab>

        </template>

        <div v-if="selectedTab=='info'">
            <championship-info/>
        </div>
        <div v-else-if="selectedTab == 'schedule'">
            <championship-schedule/>
        </div>
        <div v-else-if="selectedTab == 'teams'">
            <championship-teams/>
        </div>
        <div v-else-if="selectedTab == 'feeds'">
            <championship-feeds/>
        </div>
        <div v-else>
           <championship-stats/>
        </div>
    </va-tabs>
</template>

<script>
    import { gql,request } from 'graphql-request'
    import ChampionshipInfo from "./components/ChampionshipInfo.vue"
    import ChampionshipSchedule from "./components/ChampionshipSchedule.vue"
    import ChampionshipTeams from "./components/ChampionshipTeams.vue"
    import ChampionshipFeeds from "./components/ChampionshipFeeds.vue"
    import ChampionshipStats from "./components/ChampionshipStats.vue"
export default {
    components:{
        ChampionshipInfo,ChampionshipTeams,ChampionshipSchedule,ChampionshipFeeds,ChampionshipStats
    },
    data() {
        return {
            selectedTab:'info',
            route : '',
            championship_name:"",
            champ_type:''
        }
    },
    async mounted() {
        this.route = localStorage['routeName'];
        const GET_CHAMP_NAME = gql`
        query championshipInfo($id: Int) {
            Champs(where: { id: $id }) {
                name
                champ_type_id        
            }
        }`;
        const response = await request(this.$store.state.appUrl, GET_CHAMP_NAME, {id: Number(this.$route.params.id)});
        if(response.Champs){
            this.championship_name = response.Champs[0].name;
            localStorage['champ_type'] = response.Champs[0].champ_type_id
        }
        
    }

}
</script>

<style>

</style>