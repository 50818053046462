<template>
  <va-card class="mt-4" gradient color="#f0f0f0" stripe stripe-color="#0c4898">
    <div class="flex pt-4">
      <va-button
        v-if="user_role == 'editor'"
        color="#0c4898"
        @click="formIsOpen = !formIsOpen"
        class="float-start"
      >
        <span class="material-icons"> {{ formIsOpen ? "close" : "add" }} </span>
        {{ formIsOpen ? $t("cancel") : $t("add") }}
      </va-button>
    </div>

    <va-card-content>
      <championship-news-form
        :selectedRowToEdit="selectedRowToEdit"
        :formIsOpen="formIsOpen"
        @closeForm="closeForm"
        @rowInserted="insertTableRow"
        @rowUpdated="upateRow"
      />
      <div v-if="!formIsOpen" class="va-table-responsive">
        <va-inner-loading :loading="loading">
          <div v-if="feeds.length > 0">
            <table class="va-table va-table--hoverable">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{ $t("title") }}</th>
                  <th>{{ $t("title_ar") }}</th>
                  <th>{{ $t("text") }}</th>
                  <th>{{ $t("text_ar") }}</th>
                  <th>{{ $t("publish_at") }}</th>
                  <th>{{ $t("actions") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(feed, index) in feeds" :key="feed.id">
                  <td>{{ index + offset + 1 }}</td>
                  <td>{{ feed.title }}</td>
                  <td>{{ feed.title_ar }}</td>
                  <td style="line-break: anywhere;" v-html="feed.text.slice(0, 75)"></td>
                  <td style="line-break: anywhere;" v-html="feed.text_ar.slice(0, 75)"></td>
                  <td>{{ new Date(feed.created).toLocaleDateString() }}</td>
                  <td>
                    <div
                      v-if="user_role == 'editor'"
                      style="display: flex; justify-content: space-evenly"
                    >
                      <va-button
                        color="info"
                        @click="editRow(feed)"
                        icon="edit"
                        flat
                      />
                      <va-button
                        @click="deleteFeed(feed.id)"
                        color="danger"
                        icon="trash"
                        flat
                      />
                      <va-button @click="showDetails(feed)" icon="eye" flat />
                    </div>
                    <div
                      v-else
                      style="display: flex; justify-content: space-evenly"
                    >
                      <va-button @click="showDetails(feed)" icon="eye" flat />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <va-pagination
              v-if="pages > 1"
              color="#0a4695"
              style="direction: ltr"
              class="mt-5 float-end"
              v-model="currentPage"
              :visible-pages="3"
              :pages="pages"
            />
          </div>

          <div v-else>
            <div
              class="col align--center justify--space-evenly"
              style="height: 35vh"
            >
              <h1 style="font-size: 32px">
                {{ $t("no_feeds") }}
                <span class="material-icons"> feed </span>
              </h1>
            </div>
          </div>
        </va-inner-loading>
      </div>
    </va-card-content>
  </va-card>
  <!-- Detail Modal -->
  <va-modal
    v-model="showModal"
    :title="selectedfeedDetailsTitle"
    hide-default-actions
    fullscreen
  >
    <div
      v-html="selectedfeedDetailsText"
      style="line-height: 1.9 !important"
    ></div>
  </va-modal>
</template>

<script>
import { gql, request } from "graphql-request";
import ChampionshipNewsForm from "@/components/forms/news/ChampionshipNewsForm.vue";
export default {
  name: "ChampionshipFeeds",
  components: {
    ChampionshipNewsForm,
  },
  data() {
    return {
      feeds: [],
      feedsCount: null,
      pages: null,
      currentPage: 1,
      limit: 10,
      offset: 0,
      formIsOpen: false,
      user_id: JSON.parse(localStorage["userData"])?.id,
      user_role: JSON.parse(localStorage["userData"]).user_role?.type,
      selectedRowToEdit: {},
      selectedfeedDetails: {},
      showModal: false,
      loading: true,
    };
  },
  async mounted() {
    const MY_CHAMPIONSHIPS_COUNT = gql`
      query ($id: Int) {
        feeds: ChampMediaCount(where: { champ_id: $id }) {
          count
        }
      }
    `;
    const counterResponse = await request(
      this.$store.state.appUrl,
      MY_CHAMPIONSHIPS_COUNT,
      { id: Number(this.$route.params.id) }
    );
    this.feedsCount = counterResponse.feeds.count;
    this.pages = Math.ceil(this.feedsCount / this.limit);
    this.getchampNews();
  },
  methods: {
    insertTableRow(row) {
      this.feeds.push(row);
      this.formIsOpen = false;
    },
    async getchampNews() {
      this.loading = true;
      this.offset = (this.currentPage - 1) * this.limit;
      const CHAMPIONSHIP_NEWS = gql`
        query ($id: Int, $limit: Int, $offset: Int) {
          ChampMedia(
            where: { champ_id: $id }
            limit: $limit
            offset: $offset
            order: "reverse:created"
          ) {
            title
            title_ar
            id
            path
            type
            text
            text_ar
            created
            champ {
              name
            }
          }
        }
      `;
      const response = await request(
        this.$store.state.appUrl,
        CHAMPIONSHIP_NEWS,
        {
          id: Number(this.$route.params.id),
          limit: this.limit,
          offset: this.offset,
        }
      );
      this.feeds = response.ChampMedia;
      this.loading = false;
    },
    showDetails(feed) {
      this.selectedfeedDetails = {};
      this.selectedfeedDetails = Object.assign({}, feed);
      this.showModal = true;
    },
    closeForm() {
      this.formIsOpen = false;
      this.selectedRowToEdit = {};
    },
    upateRow(row) {
      this.getchampNews();
      this.selectedRowToEdit = {};
      this.formIsOpen = false;
    },
    editRow(feed) {
      this.selectedRowToEdit = { ...feed };
      this.formIsOpen = true;
    },
    async deleteFeed(id) {
      this.$swal
        .fire({
          title: `${this.$t("sure?")}`,
          text: this.$t("delete_text"),
          icon: "question",
          showCancelButton: true,
          
          confirmButtonColor: "#e42222",
          confirmButtonText: this.$t("delete"),
          cancelButtonText: this.$t("cancel"),
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            const DELETE_CHAMP_FEED = gql`
              mutation ($id: Int!) {
                ChampMediaDelete(id: $id)
              }
            `;
            try {
              const response = await request(
                this.$store.state.appUrl,
                DELETE_CHAMP_FEED,
                { id }
              );
              this.loading = false;

              if (response.ChampMediaDelete) {
                this.$swal.fire({
                  icon: "success",
                  title: this.$t("delete_item_success"),
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.getchampNews();
              }
            } catch (error) {
              console.log(error);
              this.$swal.fire({
                icon: "warning",
                title: this.$t("delete_item_success"),
                showConfirmButton: false,
                timer: 1500,
              });
            }
          }
        });
    },
  },
  computed: {
    selectedfeedDetailsTitle(){
      return this.$i18n.locale == "gb"? this.selectedfeedDetails.title : this.selectedfeedDetails.title_ar 
    },
    selectedfeedDetailsText(){
      return this.$i18n.locale == "gb"? this.selectedfeedDetails.text : this.selectedfeedDetails.text_ar 
    },
  },
  watch: {
    currentPage() {
      this.getchampNews();
    },
  },
};
</script>

<style scoped>
.float-start {
  display: block;
  margin-right: auto;
  margin-left: 2.25rem;
}
body.ar .float-start {
  margin-left: auto;
  margin-right: 2.25rem;
}
</style>
